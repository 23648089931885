import React from 'react';
import PropTypes from 'prop-types';
import ListGroup from '../ListGroup';

const DataCard = ({ details, title, paras, titleSx, wrapperSx }) => {
  const titleStyled = {
    color: 'warning',
    fontFamily: 'tertiary',
    fontSize: 5,
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '30px',
    mb: '14px',
    ...titleSx,
  };
  const wrapperStyled = {
    breakInside: 'avoid',
    color: 'grays.3',
    fontFamily: 'tertiary',
    fontSize: 3,
    fontStyle: 'normal',
    fontWeight: 'regular',
    lineHeight: '28px',
    pageBreakInside: 'avoid',
    pb: 5,
    ...wrapperSx,
  };
  return (
    <ListGroup
      paras={paras}
      sx={{
        fontWeight: 'regular',
      }}
      title={title}
      titleSx={titleStyled}
      wrapperSx={wrapperStyled}
    >
      {details.length > 0 &&
        details.map((detail, index) => (
          <span key={`intro-${index}`}>{detail.point}</span>
        ))}
    </ListGroup>
  );
};

DataCard.propTypes = {
  /* data: PropTypes.shape({
    details: PropTypes.PropTypes.arrayOf(PropTypes.node),
    title: PropTypes.string,
  }).isRequired, */
  details: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  paras: PropTypes.bool,
  title: PropTypes.string,
  titleSx: PropTypes.shape({}),
  wrapperSx: PropTypes.shape({}),
};

DataCard.defaultProps = {
  paras: false,
  title: '',
  titleSx: {},
  wrapperSx: {},
};

export default DataCard;
