import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';
import { aboutUsSubPages, servicesSubPages } from '../../data/pages';
import SEO from '../SEO';
import Container from '../Container';
import Title from '../Title';
import Aside from '../Aside';
import ContentArea from '../ContentArea';
import PageBanner from '../PageBanner';
import TestimonialCarousel from '../TestimonialCarousel';
import AsideNav from '../AsideNav';
import DataCard from '../DataCard';
import AboutusContainer from '../../containers/AboutusContainer';

const AboutUs = ({ page }) => {
  const containerStyled = {
    display: 'flex',
    flexDirection: 'column',
    mb: 10,
  };
  const pageTitleStyled = {
    color: 'grays.4',
    display: ['block', null, null, 'none', null],
    fontFamily: 'tertiary',
    fontSize: 9,
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '35px',
    mb: 5,
    mt: 5,
    textTransform: 'uppercase',
  };
  const dataCardWrapperStyled = {
    columnCount: '1',
    columnGap: [null, '30px', null, null, '120px'],
    flex: 'none',
    width: '100%',
  };
  const ContentAreaStyled = {
    mb: -5,
    ml: ['auto', null, null, null, null],
    mr: 0,
    width: ['100%', null, null, 'calc(100% - 320px)', 'maxWidths.md'],
  };
  const asideTitleStyled = {
    color: 'grays.3',
    fontSize: 4,
    letterSpacing: 'normal',
    lineHeight: '23px',
    textTransform: 'Capitalize',
  };
  const asideLinkStyled = {
    fontSize: 3,
    fontWeight: 'regular',
    lineHeight: '23px',
  };
  const asideSpecialLinksStyled = {
    '&:hover': {
      bg: 'warning',
      color: 'white',
    },
    color: 'grays.3',
    fontWeight: 'medium',
    mt: '12px',
  };
  const asideWrapperStyled = {
    mt: [2, null, null, null, '40px', null],
  };

  return (
    <AboutusContainer>
      {(aboutUsData) => {
        const pageName = page || 'overview';
        const detailInfo = aboutUsData
          .map((ele) => {
            return ele.id === pageName ? ele : null;
          })
          .find((el) => el);
        return (
          <Box>
            <Box>
              <SEO />
              {/* <SEO description="" title={`${detailInfo.title} | Overview`} /> */}
              <PageBanner image={detailInfo.bannerImage} />
              <Container sx={containerStyled}>
                <Title
                  sx={{
                    ...pageTitleStyled,
                    display: ['none', null, null, 'block', null],
                    ml: 'auto',
                    mt: '55px',
                    width: [
                      '100%',
                      null,
                      null,
                      'calc(100% - 320px)',
                      'maxWidths.md',
                    ],
                  }}
                >
                  {detailInfo.title}
                </Title>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: ['column', null, null, 'row', null],
                  }}
                >
                  <Aside>
                    {aboutUsSubPages.length > 0 && (
                      <AsideNav links={aboutUsSubPages} title="ABOUT US" />
                    )}
                    {page === 'overview' && servicesSubPages.length > 0 && (
                      <AsideNav
                        links={servicesSubPages}
                        linksSx={asideLinkStyled}
                        specialLinks={['construction economics']}
                        specialLinksSx={asideSpecialLinksStyled}
                        title="Quicklinks To Our Services"
                        titleSx={asideTitleStyled}
                        wrapperSx={asideWrapperStyled}
                      />
                    )}
                  </Aside>
                  <ContentArea sx={ContentAreaStyled}>
                    <Title sx={pageTitleStyled}>{detailInfo.title}</Title>
                    {detailInfo.intro && (
                      <DataCard
                        details={detailInfo.intro}
                        paras
                        wrapperSx={{ fontWeight: 'medium' }}
                      />
                    )}
                    <Box sx={dataCardWrapperStyled}>
                      {detailInfo.content &&
                        detailInfo.content.length > 0 &&
                        detailInfo.content.map((dataList, index) => {
                          if (dataList.points && dataList.points.length > 0) {
                            return (
                              <DataCard
                                key={`data-card-${index}`}
                                details={dataList.points}
                                title={dataList.subtitle}
                                titleSx={{ fontSize: 4, fontWeight: 'medium' }}
                                wrapperSx={{ lineHeight: '30px' }}
                              />
                            );
                          }
                          return null;
                        })}
                    </Box>
                  </ContentArea>
                </Box>
              </Container>
              {detailInfo.testimonials && (
                <TestimonialCarousel
                  slides={detailInfo.testimonials}
                  title={detailInfo.bottomBannerTitle}
                />
              )}
            </Box>
          </Box>
        );
      }}
    </AboutusContainer>
  );
};

AboutUs.propTypes = {
  page: PropTypes.string,
};

AboutUs.defaultProps = {
  page: '',
};

export default AboutUs;
