import PropTypes from 'prop-types';
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const AboutusContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allAboutusJson {
          edges {
            node {
              bannerImage
              id
              bottomBannerTitle
              content {
                points {
                  point
                }
                subtitle
              }
              intro {
                point
              }
              testimonials {
                testimonial
                testimonialCompany
                testimonialPerson
              }
              title
            }
          }
        }
      }
    `}
    render={({ allAboutusJson: { edges: aboutUsData } }) =>
      children(
        aboutUsData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

AboutusContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default AboutusContainer;
