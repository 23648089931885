import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';

const CallUs = ({ sx }) => {
  return (
    <Button
      as="a"
      dummy
      hoverSx={{ bg: 'grays.11', boxShadow: 2, color: 'grays.9' }}
      href="tel:+(703)560-2200"
      sx={{
        bg: 'warning',
        color: 'white',
        fontFamily: 'secondary',
        fontSize: 2,
        fontStyle: 'normal',
        fontWeight: 'semiBold',
        height: '42px',
        lineHeight: '19px',
        px: 'unset',
        py: 'unset',
        textAlign: 'center',
        width: '164px',
        ...sx,
      }}
    >
      <span>Call Us</span>
    </Button>
  );
};

CallUs.propTypes = {
  sx: PropTypes.shape({}),
};

CallUs.defaultProps = {
  sx: {},
};

export default CallUs;
