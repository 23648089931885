import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';
import LinkTo from '../LinkTo';
import downArrow from '../../images/icons/arrow-left-icon-dark.svg';
import Img from '../Img';

const AsideNav = ({
  links,
  linksActiveSx,
  linksSx,
  linksWrapperSx,
  specialLinks,
  specialLinksActiveSx,
  specialLinksSx,
  title,
  titleSx,
  wrapperSx,
}) => {
  const [toggled, setToggled] = useState(false);
  const linkWrapperStyled = {
    pt: '12px',
    ...linksWrapperSx,
  };
  const linkStyled = {
    color: 'grays.3',
    display: 'inline-block',
    fontFamily: 'tertiary',
    fontSize: 4,
    fontStyle: 'normal',
    fontWeight: 'medium',
    lineHeight: '24px',
    py: '12px',
    textTransform: 'capitalize',
    width: '100%',
    ...linksSx,
  };
  const linksActiveStyled = {
    color: 'warning',
    ...linksActiveSx,
  };
  const specialLinksActiveStyled = {
    bg: 'warning',
    color: 'white',
    ...specialLinksActiveSx,
  };
  const specialLinksStyled = {
    '&:hover': {
      bg: 'warning',
    },
    ...linkStyled,
    bg: 'grays.2',
    color: 'white',
    mt: [3, null, null, 7, null],
    px: 2,
    py: 1,
    textAlign: 'center',
    ...specialLinksSx,
  };
  const titleStyled = {
    color: 'grays.9',
    flex: 'auto',
    fontFamily: 'tertiary',
    fontSize: 6,
    fontStyle: 'normal',
    fontWeight: 'medium',
    letterSpacing: '-0.05em',
    lineHeight: '32px',
    pb: 1,
    textTransform: 'uppercase',
    ...titleSx,
  };
  const titleWrapperSx = {
    alignItems: 'center',
    borderBottom: '1px solid',
    borderColor: 'grays.2',
    display: 'flex',
  };
  const collapsibleStyled = {
    maxHeight: [toggled ? '100rem' : '0', null, null, '100rem', null],
    overflow: 'hidden',
    transition: 'all .35s 0s ease-in-out',
  };
  const collapsibleTriggerStyled = {
    display: ['flex', null, null, 'none', null],
    height: '20px',
    justifyContent: 'center',
    transform: toggled ? 'rotate(90deg)' : 'rotate(-90deg)',
    transition: 'all .35s 0s ease-in-out',
    width: '20px',
  };
  const toggleStyled = {
    maxHeight: '100%',
  };
  return (
    <Box sx={wrapperSx}>
      <Box sx={titleWrapperSx}>
        <Box as="h2" sx={titleStyled}>
          {title}
        </Box>
        <Box onClick={() => setToggled(!toggled)} sx={collapsibleTriggerStyled}>
          <Img alt="" src={downArrow} sx={toggleStyled} />
        </Box>
      </Box>
      {links.length > 0 && (
        <Box sx={collapsibleStyled}>
          <Box sx={linkWrapperStyled}>
            {links.map((link, index) => {
              const isSpecialLink =
                specialLinks.length > 0 &&
                specialLinks.includes(link.label.toLowerCase());
              return isSpecialLink ? (
                <LinkTo
                  key={`${link.label}-${index}`}
                  activeSx={specialLinksActiveStyled}
                  sx={specialLinksStyled}
                  to={link.path}
                >
                  {link.label}
                </LinkTo>
              ) : (
                <LinkTo
                  key={`${link.label}-${index}`}
                  activeSx={linksActiveStyled}
                  sx={linkStyled}
                  to={link.path}
                >
                  {link.label}
                </LinkTo>
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

AsideNav.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  linksActiveSx: PropTypes.shape({}),
  linksSx: PropTypes.shape({}),
  linksWrapperSx: PropTypes.shape({}),
  specialLinks: PropTypes.arrayOf(PropTypes.string),
  specialLinksActiveSx: PropTypes.shape({}),
  specialLinksSx: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
  titleSx: PropTypes.shape({}),
  wrapperSx: PropTypes.shape({}),
};

AsideNav.defaultProps = {
  linksActiveSx: {},
  linksSx: {},
  linksWrapperSx: {},
  specialLinks: [],
  specialLinksActiveSx: {},
  specialLinksSx: {},
  titleSx: {},
  wrapperSx: {},
};

export default AsideNav;
