import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';

const ContentArea = ({ children, sx }) => {
  const containerStyled = {
    flex: 'none',
    width: '100%',
    ...sx,
  };
  return <Box sx={containerStyled}>{children}</Box>;
};

ContentArea.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.shape({}),
};

ContentArea.defaultProps = {
  sx: {},
};

export default ContentArea;
