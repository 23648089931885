import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';
import Banner from '../Banner';
import Container from '../Container';

const PageBanner = ({ bannerAttr, image, children }) => {
  const pageBannerInnerStyled = {
    maxWidth: '100%',
    mb: '3',
    mt: 'auto',
    position: 'relative',
    pr: [3, null, null, null, 6, null],
    textAlign: 'right',
    width: '100%',
  };
  const bannerAttrStyled = {
    color: 'white',
    fontFamily: 'tertiary',
    fontSize: 1,
    fontStyle: 'normal',
    fontWeight: 'regular',
    lineHeight: '15px',
  };
  return (
    <Banner
      bgImage={image}
      imageSx={{
        backgroundPosition: 'center bottom',
        opacity: '1.0',
      }}
      sx={{ minHeight: ['130px', null, null, '236px', null] }}
    >
      {bannerAttr ? (
        <Container sx={pageBannerInnerStyled}>
          <Box as="span" sx={bannerAttrStyled}>
            {bannerAttr}
          </Box>
        </Container>
      ) : null}
      {children}
    </Banner>
  );
};

PageBanner.propTypes = {
  bannerAttr: PropTypes.string,
  children: PropTypes.node,
  image: PropTypes.string.isRequired,
};

PageBanner.defaultProps = {
  bannerAttr: '',
  children: null,
};

export default PageBanner;
