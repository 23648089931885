import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';
import Carousel from '../Carousel';
import ArrowLeftIcon from '../../images/icons/arrow-left-icon-dark.svg';
import ArrowRightIcon from '../../images/icons/arrow-right-icon-dark.svg';
import Title from '../Title';
import Container from '../Container';
import CallUs from '../CallUsButton';

const TestimonialCarousel = ({ title, slides, ...rest }) => {
  const contentAnimationStyled = {
    '.slick-slide & > *': {
      animation: `fade-out 0.5s 0s cubic-bezier(0.390, 0.575, 0.565, 1.000) both`,
    },
    '.slick-slide.slick-active & > *:nth-child(1)': {
      animation: `fade-in-bottom 0.5s .5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both`,
    },
    '.slick-slide.slick-active & > *:nth-child(2)': {
      animation: `fade-in-bottom 0.5s .65s cubic-bezier(0.390, 0.575, 0.565, 1.000) both`,
    },
    '.slick-slide.slick-active & > *:nth-child(3)': {
      animation: `fade-in-bottom 0.5s .75s cubic-bezier(0.390, 0.575, 0.565, 1.000) both`,
    },
    '@keyframes fade-in-bottom': {
      '0%': {
        opacity: 0,
        transform: `translateY(50px) scale(0.9)`,
      },
      '100%': {
        opacity: 1,
        transform: 'translateY(0) scale(1)',
      },
    },
    '@keyframes fade-out': {
      '0%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0,
      },
    },
  };
  const testimonialsStyled = {
    color: 'grays.3',
    fontFamily: 'tertiary',
    fontSize: 4,
    fontStyle: 'normal',
    fontWeight: 'regular',
    lineHeight: '30px',
    maxWidth: 'maxWidths.md',
    mx: 'auto',
    textAlign: 'center',
  };
  const titleStyled = {
    color: 'grays.3',
    fontFamily: 'tertiary',
    fontSize: 9,
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '30px',
    mb: '28px',
    textAlign: 'center',
  };
  const carouselStyled = {
    '&.slick-slider': {
      px: [5, null, null, 0, null],
    },
  };
  const wrapperStyled = {
    '&:hover': {
      '.slick-next, .slick-prev': {
        opacity: '1',
        transform: 'translateX(0)',
      },
    },
    bg: 'grays.0',
    pb: 3,
    pt: '66px',
  };
  const containerStyled = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    px: ['0', null, null, 3, null],
  };
  const setSlide = (item, index) => (
    <Box key={`testimonial-slide-${index}`} sx={contentAnimationStyled}>
      <Box sx={testimonialsStyled}>
        <Box
          as="p"
          sx={{
            mb: 5,
          }}
        >
          {item.testimonial}
        </Box>
        <Box
          as="p"
          sx={{
            fontWeight: 'bold',
          }}
        >
          {item.testimonialPerson}
        </Box>
        <Box as="p">{item.testimonialCompany}</Box>
      </Box>
    </Box>
  );
  return (
    <Box sx={wrapperStyled}>
      <Container sx={containerStyled}>
        {title && <Title sx={titleStyled}>{title}</Title>}
        <Box
          sx={{
            mb: 7,
            width: '100%',
          }}
        >
          <Carousel
            carouselSx={carouselStyled}
            controls={{
              arrowLeft: ArrowLeftIcon,
              arrowRight: ArrowRightIcon,
              controlNextSx: {
                mr: '-9px',
              },
              controlPrevSx: {
                ml: '-9px',
              },
              controlsSx: {
                height: '34px',
                top: [null, null, null, 7, null],
                width: '34px',
              },
            }}
            slides={slides.map((slide, index) => setSlide(slide, index))}
            {...rest}
          />
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CallUs />
        </Box>
      </Container>
    </Box>
  );
};

TestimonialCarousel.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string,
};

TestimonialCarousel.defaultProps = {
  title: '',
};

export default TestimonialCarousel;
