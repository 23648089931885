import React from 'react';
import { Router, Redirect } from '@reach/router';
import NotFoundPage from './404';
import AboutUs from '../components/AboutUs/index';
import { aboutUsData } from '../data/aboutUsData';

const AboutUsRouter = (props) => (
  <Router>
    <Redirect from="/about-us/" noThrow to="/about-us/overview" />
    {aboutUsData &&
      Object.keys(aboutUsData).map((page) => {
        return (
          <AboutUs
            key={`about-us-${page}`}
            {...props}
            page={page}
            path={`/about-us/${page}/`}
          />
        );
      })}
    <NotFoundPage default />
  </Router>
);

export default AboutUsRouter;
