import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';

const Aside = ({ children, sx }) => {
  const containerStyled = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: [null, null, null, '270px', null],
    mt: [7, null, null, 0, null, null],
    mx: ['auto', null, null, 0, null],
    width: '100%',
    ...sx,
  };
  return <Box sx={containerStyled}>{children}</Box>;
};

Aside.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.shape({}),
};

Aside.defaultProps = {
  sx: {},
};

export default Aside;
